import React from 'react';

import styles from './GemsPackage.module.scss';

import background from '../../assets/images/gems/shop_gems_background.png';
import gems1 from '../../assets/images/gems/1_HandfulOfGems.png';
import gems2 from '../../assets/images/gems/2_SackOfGems.png';
import gems3 from '../../assets/images/gems/3_ChestOfGems.png';
import gems4 from '../../assets/images/gems/4_BarrelOfGems.png';
import gems5 from '../../assets/images/gems/5_MountainOfGems.png';
import gems6 from '../../assets/images/gems/6_VaultOfGems.png';
import WarToken from '../../assets/images/war_token.png';

import { formatNumber } from '../../shared/helpers';

const GemsPackage = ({
    ImageId,
    OldPrice,
    NewPrice,
    OfferId,
    Type,
    selectOfferById,
    currency,
    gemsPackageNames,
    WarTokens
}) => {
    const nameId = ImageId;
    const quantityId = ImageId;
    const gemsImages = [gems1, gems2, gems3, gems4, gems5, gems6];
    // const gemsPackageNames = ['Hendful Of Gems', 'Sack Of Gems', 'Chest Of Gems', 'Barrel Of Gems', 'Mountain Of Gems', 'Vault Of Gems'];
    const gemsPackageQuantity = ['800', '5,000', '12,000', '25,000', '65,000', '140,000'];

    return (
        <div className={styles.wrapper} onClick={() => selectOfferById(OfferId, Type, gemsImages[ImageId - 1], gemsPackageNames[nameId - 1], gemsPackageQuantity[quantityId - 1])}>
            <img src={background} alt={''} className={styles.background} />
            <div className={styles.name}>{gemsPackageNames[nameId - 1]}</div>
            <div className={styles.quantity}>{gemsPackageQuantity[quantityId - 1]}</div>
            <img src={gemsImages[ImageId - 1]} alt={''} className={styles.gemsImage} />
            <div className={styles.pricesWrapper}>
                <div className={styles.oldPrice}>
                    {OldPrice.toFixed(2)} {currency}
                    <div className={'stroke'}></div>
                </div>
                <div className={styles.newPrice}>{NewPrice.toFixed(2)} {currency}</div>
            </div>
            <div className="warTokensWrapper">
                <div>+&nbsp;</div>
                <div>{formatNumber(WarTokens)}</div>
                <img className="warTokenImg" src={WarToken} alt='' />
            </div>
        </div>
    );
};

export default GemsPackage;